/* eslint-disable max-lines */
/* eslint-disable max-len */

import { useContext } from 'react';

import ProposalsContext from '@DEPRECATED/context/Proposals/context';
import { DateTime } from 'luxon';
import { v4 as uuid } from 'uuid';

export interface ConditionTemplateProps {
  id: string;
  name: string;
  type: string;
  template: string;
  value?: string;
  order?: number;
  deleted?: boolean;
  added?: boolean;
}

export interface TemplateVariablesProps {
  dia_vencimento: string;
  tipo_dia_vencimento: string;
  indice: string;
  data_base: string;
}

export const TAX: ConditionTemplateProps = {
  id: uuid(),
  type: 'tax',
  name: 'Tributos',
  template:
    'Estão incluídos no Preço PIS e COFINS, exceto para as empresas localizadas na Zona Franca de Manaus. O ICMS não está incluído no preço. Considerando a localização do consumidor, Zona Franca de Manaus, a alíquota de PIS e COFINS incluída no preço observará o Benefício Fiscal do comprador, desde que vigente e aplicável à época do faturamento mensal. Em hipótese alguma haverá algum abatimento ou desconto no valor a ser pago pela energia elétrica comprada.',
};

export const PAYMENT: ConditionTemplateProps = {
  id: uuid(),
  type: 'payment',
  name: 'Pagamentos',
  template:
    'O vencimento mensal da fatura será no {{dia_vencimento}} {{tipo_dia_vencimento}} do mês subsequente ao fornecimento.{{complemento_vencimento}}',
};

export const OVERDUE: ConditionTemplateProps = {
  id: uuid(),
  type: 'overdue',
  name: 'Atraso no Pagamento',
  template:
    'Ocorrendo atraso no pagamento, a multa será de 2% (dois por cento), acrescida de juros de 1% (um por cento) ao mês acrescida da variação positiva do IGPM pro rata die.',
};

export const GUARANTEE_SHORT: ConditionTemplateProps = {
  id: uuid(),
  type: 'guarantee_short',
  name: 'Garantias',
  template: 'Registro contra pagamento.',
};

export const GUARANTEE_LONG: ConditionTemplateProps = {
  id: uuid(),
  type: 'guarantee_long',
  name: 'Garantias',
  template:
    'Carta Fiança ou Seguro Garantia em valor equivalente a 2 (dois) meses de fornecimento incluso ICMS quando aplicável, a ser apresentada antes do inicio de fornecimento do contrato. O registro de energia na CCEE será proporcional aos meses cobertos pela garantia.',
};

export const GUARANTEE_CATEGORY: ConditionTemplateProps = {
  id: uuid(),
  type: 'guarantee_category',
  name: 'Garantias',
  template:
    'A compradora será dispensada de apresentar a garantia financeira de pagamento, sempre que seja mantido o perfil de crédito que foi pré-aprovado. Caso o perfil de crédito não seja mais elegível para a dispensa, a compradora deverá apresentar Carta Fiança, Seguro Garantia ou Depósito em Garantia em valor equivalente a 3 (três) meses de fornecimento incluso ICMS quando aplicável, a ser apresentada em até 30 dias após verificação da inelegibilidade do perfil de crédito.',
};

export const GUARANTEE_TRD_PLUS: ConditionTemplateProps = {
  id: uuid(),
  type: 'guarantee_category',
  name: 'Garantias',
  template:
    'A compradora será dispensada de apresentar a garantia financeira de pagamento, sempre que seja mantido o perfil de crédito que foi pré-aprovado. Caso o perfil de crédito não seja mais elegível para a dispensa, a compradora deverá apresentar Carta Fiança, Seguro Garantia ou Depósito em Garantia em valor equivalente a 2 (dois) meses de fornecimento incluso ICMS quando aplicável, a ser apresentada antes do início de fornecimento do contrato. O registro de energia na CCEE será proporcional aos meses cobertos pela garantia.',
};

const GUARANTEE = {
  SHORT: GUARANTEE_SHORT,
  TRD_PLUS: GUARANTEE_TRD_PLUS,
  TRD_RETAILER_SET_PRICE: GUARANTEE_CATEGORY,
  TRD_RETAILER_GUARANTEED_GAIN: GUARANTEE_CATEGORY,
  TRD_RETAILER_SET_PRICE_FLEX_FREE: GUARANTEE_CATEGORY,
  TRD_RETAILER_GUARANTEED_GAIN_FLEX_FREE: GUARANTEE_CATEGORY,
};

export const TERMINATION: ConditionTemplateProps = {
  id: uuid(),
  type: 'termination',
  name: 'Rescisão',
  template: 'Multa de 30% (trinta por cento) do saldo remanescente. Cláusula de perdas e danos aplicável.',
};

export const INVOICE: ConditionTemplateProps = {
  id: uuid(),
  type: 'invoice',
  name: 'Faturamento',
  template:
    'O faturamento mensal será aplicado sobre a medição efetiva de consumo (medição bruta) do Consumidor. Eventual instalação de geração própria no decorrer do contrato deverá ser avisada com 180 (cento e oitenta) dias de antecedência. Quando executada, o contrato automaticamente terá 85% de <i>take-or-pay</i>.',
};

export const TRD_RETAILER_INVOICE: ConditionTemplateProps = {
  id: uuid(),
  type: 'trd_retailer_invoice',
  name: 'Faturamento',
  template: 'O faturamento mensal será aplicado sobre a medição efetiva de consumo (medição bruta) do Consumidor.',
};

export const READJUST: ConditionTemplateProps = {
  id: uuid(),
  type: 'readjust',
  name: 'Reajuste',
  template:
    'Os preços terão base em {{data_base}}, e serão atualizados pela variação positiva do {{indice}} entre a data desta proposta e a data de início do fornecimento e daí a cada 12 meses pela variação do mesmo índice.',
};

export const READJUST_TRIGGER: ConditionTemplateProps = {
  id: uuid(),
  type: 'readjust_trigger',
  name: 'Reajuste',
  template:
    'Os preços (incluindo o valor de SPREAD) terão base em {{data_base}}, e serão atualizados pela variação positiva do {{indice}} entre a data desta proposta e a data de início do fornecimento e daí a cada 12 meses pela variação do mesmo índice.',
};

export const ENERGY_DEMAND: ConditionTemplateProps = {
  id: uuid(),
  type: 'energy_demand',
  name: 'Demanda de Energia',
  template: 'A COMPRADORA obriga-se a informar a VENDEDORA qualquer alteração da demanda contratada com a Distribuidora.',
};

export const ACCEPTANCE: ConditionTemplateProps = {
  id: uuid(),
  type: 'acceptance',
  name: 'Aceite da Proposta',
  template:
    'Com o aceite da proposta, esta passará a ser vinculante entre as Partes, constituindo compromisso firme, irretratável e irrevogável, sem prejuízo da posterior formalização mediante assinatura do contrato de compra e venda de energia elétrica, nos termos do artigo 427 do Código Civil. O signatário da aceitação desta proposta declara representar legalmente o Comprador, detendo todos os poderes necessários para este ato.',
};

export const TRD_TRIGGER: ConditionTemplateProps = {
  id: uuid(),
  type: 'trd_trigger',
  name: 'Gatilho',
  template:
    'O consumidor pagará mensalmente o valor de gatilho (PLD + Spread) enquanto o valor de gatilho for inferior ao preço da oferta (valor contratual reajustado). Uma vez que o valor de gatilho se iguale ou ultrapasse o preço da oferta reajustada, o valor praticado passa a ser o preço da oferta reajustada para todo o restante do período contratado.',
};

export const SCDE_ACCESS: ConditionTemplateProps = {
  id: uuid(),
  type: 'scde_access',
  name: 'Acesso ao SCDE',
  template:
    'A Compradora obriga-se a conceder o acesso para a VENDEDORA ao Sistema de Coleta de Dados (SCDE) da CCEE ou qualquer outro sistema que venha a substituí-lo durante o período de fornecimento do contrato.',
};

export const CHARGES: ConditionTemplateProps = {
  id: uuid(),
  type: 'charges',
  name: 'Encargos',
  template: 'Cobertos 100% pelo Vendedor',
};

export const LOCK_CHARGES: ConditionTemplateProps = {
  id: uuid(),
  type: 'lock_charges',
  name: 'Encargos',
  template:
    'Em janeiro do ano subsequente ao período de fornecimento, serão apurados os valores referentes aos encargos divulgados pela CCEE e pagos pela Vendedora, em seu perfil Varejista. Caso esse valor ultrapasse R$ 30,00/MWh, o valor excedente será rateado proporcionalmente ao consumo do Comprador e incluído na nota fiscal referente ao consumo de dezembro.',
};

export const OWN_GENERATION: ConditionTemplateProps = {
  id: uuid(),
  type: 'own_generation',
  name: 'Geração Própria',
  template:
    'A Compradora deverá notificar a Vendedora sobre a eventual instalação de geração de energia elétrica própria (autoprodução) com uma antecedência de 180 (cento e oitenta) dias. Quando esse evento ocorrer, será acrescido ao preço do contrato o valor de R$ 10,00/MWh. Se a notificação não for realizada no período estabelecido ou não ocorrer, o valor acrescido passará a ser de R$ 20,00/MWh.',
};

export const OWN_GENERATION_INVESTMENTS: ConditionTemplateProps = {
  id: uuid(),
  type: 'own_generation_investments',
  name: 'Investimentos Geração Própria',
  template:
    'A Compradora deverá avisar a Vendedora sobre a capacidade instalada do seu sistema de energia solar para que seja avaliada sua geração em relação às necessidades operacionais. Caso haja energia excedente, essas questões deverão ser tratadas antes da entrada no mercado livre. A verificação da compatibilidade do sistema com os requisitos técnicos do mercado, incluindo inversores, medição e integração com a rede pública, é de responsabilidade da Compradora, bem como os custos associados a esses itens.',
};

export const BLANK_ITEM: ConditionTemplateProps = {
  id: uuid(),
  name: 'Em branco',
  type: 'blank',
  template: '',
};

const TRD_RETAILERS = [
  'TRD_RETAILER_GUARANTEED_GAIN',
  'TRD_RETAILER_SET_PRICE',
  'TRD_RETAILER_GUARANTEED_GAIN_FLEX_FREE',
  'TRD_RETAILER_SET_PRICE_FLEX_FREE',
];

const ALL: ConditionTemplateProps[] = [
  TAX,
  PAYMENT,
  OVERDUE,
  GUARANTEE_SHORT,
  GUARANTEE_LONG,
  GUARANTEE_CATEGORY,
  TERMINATION,
  INVOICE,
  TRD_RETAILER_INVOICE,
  READJUST,
  READJUST_TRIGGER,
  ENERGY_DEMAND,
  ACCEPTANCE,
  TRD_TRIGGER,
  SCDE_ACCESS,
  CHARGES,
  LOCK_CHARGES,
  OWN_GENERATION,
  OWN_GENERATION_INVESTMENTS,
];

function useQuoteInformation() {
  const { data, replaceData }: { data: any; replaceData: (key: string, data: any) => any } = useContext(ProposalsContext);

  const {
    quoteInformation,
    quoteInformationTypes,
    dueDateValue,
    dueDateType,
    periodType,
    readjustmentIndex,
    baseDate,
    opportunity,
  } = data;

  const getVariables = (): TemplateVariablesProps => {
    const variables = {
      dia_vencimento: `${dueDateValue}º`,
      tipo_dia_vencimento: dueDateType === 'BUSINESS_DAY' ? 'dia útil' : 'dia',
      complemento_vencimento: getPaymentAdditionalInformation(),
      indice: readjustmentIndex as string,
      data_base: baseDate ? `${baseDate}` : '',
    };

    if (baseDate) {
      variables.data_base = DateTime.fromJSDate(baseDate as any)
        .plus({ hours: 3 })
        .setLocale('pt-BR')
        .toFormat('dd/MM/yyyy');
    }

    return variables;
  };

  const getPaymentAdditionalInformation = () => {
    if (dueDateValue <= 6 && dueDateType === 'BUSINESS_DAY') {
      return '';
    }

    let paymentInformation =
      ' Tal condição será válida e aplicável apenas e tão somente se apresentada a garantia válida de acordo com os termos desta proposta e do Contrato a ser celebrado entre as Partes. Caso contrário, o vencimento mensal da fatura será no 6º dia útil do mês subsequente ao fornecimento.';

    if (dueDateType === 'FIXED') {
      const fixedInformation =
        ' Caso tal data não seja dia útil, o vencimento será automaticamente prorrogado para o primeiro dia útil subsequente.';

      paymentInformation = fixedInformation + paymentInformation;
    }

    return paymentInformation;
  };

  const defaultTemplates = (): ConditionTemplateProps[] => {
    let templates = [TAX, PAYMENT, OVERDUE, GUARANTEE_SHORT, TERMINATION, ACCEPTANCE];
    let additional: Array<ConditionTemplateProps> = [];
    let trd_additional: Array<ConditionTemplateProps> = [];

    if (periodType === 'LONG') {
      const guarantee = GUARANTEE[opportunity.category] ?? GUARANTEE[periodType] ?? GUARANTEE_LONG;
      const trigger = opportunity?.category === 'TRD_TRIGGER' && TRD_TRIGGER;
      const readjust = opportunity?.category === 'TRD_TRIGGER' ? READJUST_TRIGGER : READJUST;
      const invoice = TRD_RETAILERS.includes(opportunity?.category) ? TRD_RETAILER_INVOICE : INVOICE;

      if (
        ['TRD_DEFAULT', 'TRD_FLAT'].includes(opportunity?.category) &&
        opportunity?.type == 'SALE' &&
        opportunity?.buyer?.crmRecordType == 'Consumidor'
      ) {
        additional.push(ENERGY_DEMAND);
      }

      additional.push(SCDE_ACCESS);

      if (TRD_RETAILERS.includes(opportunity.category)) {
        additional = [];
        trd_additional = [OWN_GENERATION, OWN_GENERATION_INVESTMENTS, CHARGES];
      }

      templates = [
        TAX,
        PAYMENT,
        readjust,
        OVERDUE,
        guarantee,
        TERMINATION,
        ...additional,
        invoice,
        ACCEPTANCE,
        ...trd_additional,
        trigger,
      ].filter((item) => !!item);
    }

    return templates;
  };

  const getTemplates = (withDeleted = false): ConditionTemplateProps[] => {
    const templates = defaultTemplatesWithServer().map((defaultTemplate) => {
      const savedTemplate: ConditionTemplateProps = quoteInformation.find(
        (savedTemplate) => savedTemplate.type === defaultTemplate.type,
      );

      return savedTemplate ? savedTemplate : defaultTemplate;
    });

    const addedTemplates: ConditionTemplateProps[] = quoteInformation.filter(
      (item) => item.added || item.type === BLANK_ITEM.type,
    );

    return [...templates, ...addedTemplates].filter((item) => !(item.deleted && !withDeleted));
  };

  const defaultTemplatesWithServer = () => {
    if (quoteInformationTypes.length === 0) {
      return defaultTemplates();
    }

    return ALL.filter((template) => quoteInformationTypes.includes(template.type));
  };

  const getAvailableTemplates = (): ConditionTemplateProps[] => {
    const usedTemplateTypes = getTemplates().map((template) => template.type);

    const availableTemplates = [
      ...[...defaultTemplates(), SCDE_ACCESS].filter((template) => !usedTemplateTypes.includes(template.type)),
      { ...BLANK_ITEM, id: uuid() },
    ];

    return Array.from(new Set(availableTemplates));
  };

  const updateQuoteInformation = (data: ConditionTemplateProps[]): ConditionTemplateProps[] =>
    replaceData('quoteInformation', data);

  const generateInitialValues = () => {
    const templates = getTemplates(true);

    const initialData = templates.map((condition) => ({
      ...condition,
      value: transformValue(condition.template),
    }));

    updateQuoteInformation(initialData);
  };

  const deleteItem = (itemId: ConditionTemplateProps) => {
    updateQuoteInformation(
      quoteInformation
        .map((item) => {
          if (item.id === itemId) {
            item.deleted = true;
          }

          return item;
        })
        .filter((item) => !(item.type === BLANK_ITEM.type && item.deleted)),
    );
  };

  const saveItem = (id: string, { name, value, template }: ConditionTemplateProps) => {
    updateQuoteInformation(
      quoteInformation.map((item) => {
        if (item.id === id) return { ...item, name, value, template };

        return item;
      }),
    );
  };

  function addItem(newItem: ConditionTemplateProps) {
    let found = false;

    const updatedQuoteInformation = quoteInformation.map((item) => {
      if (newItem.type !== BLANK_ITEM.type && item.type === newItem.type) {
        item.deleted = false;
        found = true;
      }

      return item;
    });

    if (found) {
      updateQuoteInformation(updatedQuoteInformation);
    } else {
      updateQuoteInformation([...quoteInformation, { ...newItem, added: true, value: transformValue(newItem.template) }]);
    }
  }

  function transformValue(template: string) {
    const variables = getVariables();

    return template.replace(/{{([^{}]*)}}/g, (a, b) => variables[b]);
  }

  const invalidQuoteInformation = quoteInformation.some(({ name, template, value }) => [name, template, value].includes(''));

  return {
    getVariables,
    getTemplates,
    getAvailableTemplates,
    generateInitialValues,
    deleteItem,
    saveItem,
    addItem,
    transformValue,
    invalidQuoteInformation,
  };
}

export default useQuoteInformation;
