import React, { Fragment, useEffect, useRef, useState } from 'react';

import { useForm, useFieldArray } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Box, Button, Typography, darken, IconButton } from '@material-ui/core';
import { Close, Publish, Description } from '@material-ui/icons';
import { DateTime } from 'luxon';
import { nanoid } from 'nanoid';

import IMPORT_SIMULATION_PLDS, {
  ResponseProps as ImportResponseProps,
  PayloadProps as ImportPayloadProps,
} from '@graphql/mutation/importSimulationPlds';
import SIMULATION_PLDS, { ResponseProps, PayloadProps, SimulationPLDProps } from '@graphql/query/simulationPLDs';

import useColorMode from '~/hooks/useColorMode';
import usePortfolio from '~/pages/Portfolio/usePortfolio';
import useSimulation from '~/pages/Portfolio/useSimulation';
import useSimulationRules from '~/pages/Portfolio/useSimulationRules';
import { typography, grey } from '~/theme';
import Modal, { ModalBody, ModalHeader, ModalFooter, ModalProps } from '~/theme/components/Modal';
import ShowChart from '~/theme/icons/ShortChart';

import usePeriods from '../../hooks/usePeriods';
import useStyles from './styles';

import { submarkets } from '..';

const SimulationPLD: React.ComponentType<ModalProps> = ({ isOpen, onClose, ...props }) => {
  const { colorTernary } = useColorMode();
  const { table, header, body } = useStyles();
  const { simulationId } = useSimulation();
  const { isReadOnly } = useSimulationRules();
  const { fullPeriods } = usePeriods();
  const { ...filterValues } = usePortfolio();
  const [file, setFile] = useState<File | null>(null);

  const [importPLDs] = useMutation<ImportResponseProps, ImportPayloadProps>(IMPORT_SIMULATION_PLDS, {
    onCompleted: ({ importSimulationPlds }) => {
      if (importSimulationPlds) {
        toast.success('PLDs importados com sucesso!');
      } else {
        toast.error('Nenhum PLD importado!');
      }

      setFile(null);
      loadPLDs();
    },
    onError: ({ message }) => toast.error(message),
  });

  const [loadPLDs, { data }] = useLazyQuery<ResponseProps, PayloadProps>(SIMULATION_PLDS, {
    fetchPolicy: 'no-cache',
    variables: { simulationId: simulationId as string, ...filterValues },
  });

  const { control, setValue } = useForm<ResponseProps>();

  useEffect(() => {
    if (data?.predicted && data?.high && data?.low) {
      setValue('predicted', data?.predicted as SimulationPLDProps[]);
      setValue('high', data?.high as SimulationPLDProps[]);
      setValue('low', data?.low as SimulationPLDProps[]);
    }
  }, [data, setValue]);

  const { fields: fieldsPredicted } = useFieldArray({ name: 'predicted', control });
  const { fields: fieldsHigh } = useFieldArray({ name: 'high', control });
  const { fields: fieldsLow } = useFieldArray({ name: 'low', control });

  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const handleClick = () => hiddenFileInput?.current?.click();

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setFile(target?.files?.[0] as File);
  };

  const handleApply = () => {
    importPLDs({
      variables: { simulationId: simulationId as string, file: file as File },
    });
  };

  const handleCancel = () => setFile(null);

  useEffect(() => {
    if (simulationId && isOpen) loadPLDs();
  }, [loadPLDs, simulationId, isOpen]);

  const getNumberFormat = (
    value: number,
    options: Intl.NumberFormatOptions = {
      currency: 'BRL',
      style: 'currency',
      minimumFractionDigits: 2,
    },
  ) => new Intl.NumberFormat('pt-BR', options).format(value);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} maxWidth={false} {...props}>
        <ModalHeader style={{ height: '64px', width: '1000px' }}>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" width="962px">
            <Typography variant="h5">PLD</Typography>
            <IconButton
              size="small"
              color="secondary"
              onClick={(event) => onClose(event, 'backdropClick')}
              style={{ border: 'none' }}
            >
              <Close style={{ width: '20px', height: '20px' }} />
            </IconButton>
          </Box>
        </ModalHeader>
        <ModalBody style={{ height: '668px', width: '1000px' }}>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            {['predicted', 'high', 'low'].map((type, index) => {
              const isPredicted = type === 'predicted';
              const title = isPredicted ? 'previsto' : type === 'high' ? 'alto' : 'baixo';

              return (
                <Box key={index} className={table} style={isPredicted ? { outlineColor: '#4383F7' } : { outline: 'none' }}>
                  <Box
                    className={header}
                    bgcolor={isPredicted ? '#4383F7' : 'transparent'}
                    style={isPredicted ? { borderColor: '#4383f7' } : { border: 'none' }}
                  >
                    <Typography variant="h5" style={isPredicted ? { color: '#FFF', fontWeight: 600 } : { color: 'inherit' }}>
                      Cenário {title}
                    </Typography>
                  </Box>
                  <Box className={body}>
                    <Box
                      display="grid"
                      gridTemplateColumns={`30px repeat(${fullPeriods.length}, 110px)`}
                      alignItems="center"
                      justifyContent="space-between"
                      height="40px"
                      width={`${fullPeriods.length * 110 + 30}px`}
                    >
                      <Box />
                      {fullPeriods.map((period, index) => (
                        <Box
                          key={index}
                          display="flex"
                          alignItems="center"
                          justifyContent="end"
                          height="40px"
                          p="5px"
                          {...typography.body2}
                          style={{ color: '#868686' }}
                        >
                          {/-/.test(period)
                            ? DateTime.fromFormat(period, 'yyyy-MM')
                                .toFormat('LLL / yy', { locale: 'pt-BR' })
                                .replace('.', '')
                                .toUpperCase()
                            : period}
                        </Box>
                      ))}
                    </Box>
                    {Object.keys(submarkets).map((submarketItem) => {
                      const submarket = submarketItem.toUpperCase();

                      const getData = () => {
                        if (isPredicted) return fieldsPredicted?.filter((item) => item.submarket === submarket);
                        else if (type === 'high') return fieldsHigh?.filter((item) => item.submarket === submarket);
                        else return fieldsLow?.filter((item) => item.submarket === submarket);
                      };

                      const itemsFromSubmarket = getData() as SimulationPLDProps[];

                      const PldItems = fullPeriods.map((period) => {
                        const pldItem = itemsFromSubmarket?.find(({ competence }) => competence === period);

                        return (
                          <Box
                            key={`${pldItem?.id}.${period}`}
                            display="flex"
                            alignItems="center"
                            justifyContent="end"
                            height="40px"
                            width="110px"
                            padding="4px"
                          >
                            <Box
                              display="flex"
                              fontWeight={600}
                              bgcolor={darken(grey[colorTernary(600, 100)], 0.02)}
                              color={grey[colorTernary(300, 800)]}
                              borderRadius="4px"
                              padding="2px 4px"
                              style={{ pointerEvents: 'none' }}
                              {...typography.body2}
                            >
                              <ShowChart style={{ width: '18px', height: '18px', paddingRight: '4px' }} />
                              {getNumberFormat(pldItem?.price ?? 0.0, { minimumFractionDigits: 2 })}
                            </Box>
                          </Box>
                        );
                      });

                      return (
                        <Fragment key={submarket}>
                          <Box
                            display="grid"
                            gridTemplateColumns={`30px repeat(${fullPeriods.length}, 110px)`}
                            alignItems="center"
                            justifyContent="space-between"
                            height="40px"
                            width={`${fullPeriods.length * 110 + 30}px`}
                            borderTop={`1px solid ${grey[colorTernary(700, 200)]}`}
                          >
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="center"
                              alignSelf="center"
                              height="40px"
                              {...typography.body2}
                            >
                              {submarket}
                            </Box>
                            {PldItems}
                          </Box>
                        </Fragment>
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </ModalBody>
        {!isReadOnly && (
          <ModalFooter style={{ height: '88px', width: '1000px' }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" width="1000px">
              <>
                {!file ? (
                  <Button
                    startIcon={<Publish style={{ color: `${colorTernary('#EEE', '#111')}}}` }} />}
                    variant="outlined"
                    disableElevation
                    style={{ height: '40px', width: '228px' }}
                    onClick={handleClick}
                  >
                    Importar curva PLD
                  </Button>
                ) : (
                  <Button
                    startIcon={<Description style={{ color: '#868686' }} />}
                    endIcon={<Close style={{ color: '#868686' }} />}
                    variant="contained"
                    disableElevation
                    style={{
                      height: '40px',
                      width: 'fit-content',
                      minWidth: '300px',
                      borderRadius: '0',
                      justifyContent: 'space-between',
                      background: `${colorTernary('#EEE', '#111')}}}`,
                    }}
                    onClick={handleCancel}
                  >
                    {file.name}
                  </Button>
                )}
                <input
                  name="file"
                  type="file"
                  key={file?.name?.toString() ?? nanoid()}
                  ref={hiddenFileInput}
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
              </>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="end"
                width="100%"
                style={{ columnGap: '12px', gridColumnGap: '8px' }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  style={{ width: '82px', height: '40px' }}
                  disabled={!file}
                  onClick={handleCancel}
                  disableElevation
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: '82px', height: '40px' }}
                  disabled={!file}
                  disableElevation
                  onClick={handleApply}
                >
                  Aplicar
                </Button>
              </Box>
            </Box>
          </ModalFooter>
        )}
      </Modal>
    </>
  );
};

export default SimulationPLD;
